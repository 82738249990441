<template>
	<v-app id="app" :style="appStyles">
		<AppBar :loading="loading"/>

		<transition name="fadeRouter" mode="out-in">
			<v-main>
				<transition name="fadeRouter" mode="out-in">
					<router-view />
				</transition>
			</v-main>
		</transition>

		<SessionExpiredDialog />
		<InvalidHashDialog />
		<HelpDialog />
		<IssueDialog />
	</v-app>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import AppBar from '@/components/AppBar'
import SessionExpiredDialog from '@/components/znap/SessionExpiredDialog'
import InvalidHashDialog from '@/components/znap/InvalidHashDialog'
import HelpDialog from '@/components/HelpDialog'
import IssueDialog from '@/components/IssueDialog.vue'

export default {
	name: 'App',

	components: {
		AppBar, SessionExpiredDialog, InvalidHashDialog, HelpDialog, IssueDialog
	},

	data() {
		return {
			sn: false,
			payload: {},
			background: '',
			drawerColor: '',
			loading: false,
		}
	},

	computed: {
		...mapGetters(['getSystem']),
		...mapGetters('theme', ['getTheme', 'getBg']),
		...mapGetters('auth', ['getHash', 'getTokenExp']),

		bgUrl() {
			if (this.getBg > 0) {
				return `url(img/bg/bg_${this.getBg}.webp)`
			} else {
				return `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.primary} 0%, ${this.$vuetify.theme.themes.light.secondary} 100%)`
			}
		},

		appStyles() {
			return {
				'background': `${this.$vuetify.theme.themes.light.primary}`,
				'background-image': `${this.bgUrl}`,
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
				'-webkit-background-size': 'cover',
				'-moz-background-size': 'cover',
				'-o-background-size': 'cover',
			}
		}
	},

	methods:{
		...mapMutations('auth', ['setSessionExpiredDialog', 'setEnableSessionExpiredDialog', 'setInvalidHashDialog', 'setUrlPathName']),
		...mapActions('auth', ['getHashAuth', 'hashAuthenticate', 'logout']),
		...mapActions('theme', ['randomBg']),

		setTheme() {
			const colours = require(`@/plugins/themes/${this.getTheme}`)
			this.$vuetify.theme.themes.light = colours.default
		},

		async isValidHash(hash) {
			try {
				const res = await this.$http.post(this.$ipUser + 'user/validate-hash', { ...hash })
				if (res) {
					return !!res.data.isValidHash
				}
			} catch (err) {
				this.$fnError(err)
				return false
			}
		},

		isValidToken(tokenExp) {
			let now = Math.floor(Date.now() / 1000)
            return now - tokenExp <= 0
		},

		removeHashFromURL() {
			window.history.replaceState({}, document.title, "" + "/")
		},

		successLogin(route = null) {
			if (route) {
				let path = `/${route}`
				this.$router.push(path)
			}

			this.setEnableSessionExpiredDialog(true)
			this.removeHashFromURL()
			return this.loading = false
		}
	},

	async created() {
		this.setTheme()

		if (this.getBg > 0) {
			this.randomBg()
		}

		this.loading = true

		this.setEnableSessionExpiredDialog(false)
		this.setSessionExpiredDialog(false)
		let hash = null
		let url = new URL(window.location.href)
		let hashUrl = false
		let hashStore = false
		let isValidSessionToken = false
		let isValidHash = false
		if (url.pathname !== '/') this.setUrlPathName(url.pathname)

		hashUrl = !!url.searchParams.get("token")

		if (hashUrl) {
			hash = url.searchParams.get("token")
		} else {
			hash = this.getHash

			if (hash) {
				hashStore = true
			}
		}

		if (hash) {
			isValidHash = await this.isValidHash({ hash })
		}

		let tokenExp = this.getTokenExp
		if (tokenExp) {
			isValidSessionToken = this.isValidToken(tokenExp)
		}

		if (!hashUrl && !hashStore && !isValidSessionToken && !isValidHash) {
			this.loading = false
			return this.setInvalidHashDialog(true)
		}

		if (hashUrl && isValidHash) {
			const auth = await this.hashAuthenticate(hash)
			if (auth === 'success') {
				let route = url.searchParams.get("route")
				this.setInvalidHashDialog(false)
				this.setEnableSessionExpiredDialog(true)
				return this.successLogin(route)
			} else {
				let err = auth
				this.loading = false
				this.$fnError(err)
			}
		}

		if (hashStore && isValidHash && !isValidSessionToken) {
			this.loading = false
			return this.setSessionExpiredDialog(true)
		}

		if ((hashUrl || hashStore) && !isValidHash && !isValidSessionToken) {
			this.loading = false
			return this.setInvalidHashDialog(true)
		}

		if (!isValidHash && isValidSessionToken) {
			const auth = await this.getHashAuth()
			if (auth === 'success') {
				let route = url.searchParams.get("route")
				return this.successLogin(route)
			} else if (auth === 'login') {
				this.loading = false
				return this.setInvalidHashDialog(true)
			} else {
				let err = auth
				this.$fnError(err)
				return this.loading = false
			}
		}

		this.loading = false
		this.setEnableSessionExpiredDialog(true)
	},
}
</script>

<style>
html {
	overflow-y: auto !important;
}

/* ::-webkit-scrollbar-track{
	display: none;
}

::-webkit-scrollbar{
	width: 8px;
	z-index:200;
	height: 5px;
}

::-webkit-scrollbar-thumb{
	background-color: rgba(0,0,0,0.3);
	border-radius: 10px;
} */

.fadeRouter-enter {
	opacity: 0;
}

.fadeRouter-enter-active {
	transition: opacity .28s ease;
}

.fadeRouter-leave-active {
	transition: opacity .28s ease;
	opacity: 0;
}

@font-face {
	font-family: 'Panton';
	font-weight: 100;
	src: url('./assets/fonts/Panton-Light.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 400;
	src: url('./assets/fonts/Panton.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 500;
	src: url('./assets/fonts/Panton-Bold.otf')
}

@font-face {
	font-family: 'Panton';
	font-weight: 900;
	src: url('./assets/fonts/Panton-Black.otf')
}
</style>