export default function setModule(request, defaultModule) {
    // defaultModule => "ZNAP_MASTER_DATA"

    let endpoint = request.url
    let module = ""

    switch (true) {
        case (endpoint.indexOf('user/menu') !== -1 ):
            module = defaultModule
            break
        case (endpoint.indexOf('user/hash/login') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('user/list/') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('user/edit/') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('client-module/list/client') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('status/list') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('status/list-options') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('customer-group/list-options') !== -1):
            module = "ZNAP_SALES_PLANNING"
            break
        case (endpoint.indexOf('notification/list') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('notification/edit') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('notification/delete') !== -1):
            module = "ZNAP_SECURITY"
            break
        case (endpoint.indexOf('sales-force/list-options') !== -1):
            module = "ZNAP_SALES_PLANNING"
            break
        case (endpoint.indexOf('sales-channel/list-options') !== -1):
            module = "ZNAP_SALES_PLANNING"
            break
        case (endpoint.indexOf('sales-force-bp/list-options') !== -1):
            module = "ZNAP_SALES_PLANNING"
            break
        default:
            module = defaultModule
    }

    return module
}