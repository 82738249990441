"use strict";

import pt from 'vuetify/es5/locale/pt'

export default {
    ...pt,

    // USER PROFILE
    name: 'Nome',
    password: 'Senha',
    changePassword: 'Alterar senha',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    cancel: 'Cancelar',
    userInformation: 'Informações do usuário',
    userInformationxs: 'Perfil',
    change: 'Alterar',
    add: 'Adicionar',
    remove: 'Remover',
    primaryEmail: 'E-mail principal',
    addAlternateEmail: 'Adicionar e-mail alternativo',
    addAlternateEmailxs: 'E-mail alternativo',
    phone: 'Telefone',
    primaryPhone: 'Telefone principal',
    addAlternatePhone: 'Adicionar telefone alternativo',
    addAlternatePhonexs: 'Tel alternativo',
    extension: 'Ramal',
    smsCellphone: 'Preencha um celular para receber SMS',
    smsCellphonexs: 'Celular para SMS',
    chooseRegisteredPhone: 'Ou escolha um telefone cadastrado',
    passwordExpirationDate: 'Data de expiração da senha',
    lastUpdate: 'Última atualização',
    saveChanges: 'Salvar alterações',
    save: 'Salvar',

    // TABLE
    export: 'Exportar planilha',
    import: 'Importar planilha',
    searchLabel: 'Pesquisar',
    reload: 'Recarregar',
    massDeleteBtn: 'Deletar itens selecionados',
    crudCreateBtn: 'Criar',
    crudDeleteBtn: 'Deletar',
    crudUpdateBtn: 'Editar',
    editingTableSnack: 'Editando linha da tabela',
    cancelTableSnack: 'Edição cancelada',
    saveTableSnack: 'Edição salva',
    toastError: 'Ops...!',
    toastSuccess: 'Êxito!',
    deleteSure: 'Você tem certeza que deseja deletar este item?',

    'company-group': {
        title: 'Grupo empresarial',
        dialog: {
            create: 'Criar grupo empresarial',
            delete: 'Deletar grupo empresarial',
            massDelete: 'Você tem certeza que deseja excluir este grupo empresarial?',
            update: 'Atualizar grupo empresarial',
        }
    },
    'company': {
        title: 'Empresa',
        dialog: {
            create: 'Criar empresa',
            delete: 'Deletar empresa',
            massDelete: 'Você tem certeza que deseja excluir esta empresa?',
            update: 'Atualizar empresa',
        }
    },
    'cost-center-type': {
        title: 'Tipo do centro de custo',
        dialog: {
            create: 'Criar tipo do centro de custo',
            delete: 'Deletar tipo do centro de custo',
            massDelete: 'Você tem certeza que deseja excluir este tipo do centro de custo?',
            update: 'Atualizar tipo do centro de custo',
        }
    },
    'cost-center': {
        title: 'Centro de custo',
        dialog: {
            create: 'Criar centro de custo',
            delete: 'Deletar centro de custo',
            massDelete: 'Você tem certeza que deseja excluir este centro de custo?',
            update: 'Atualizar centro de custo',
        }
    },
    'chart-account-version': {
        title: 'Versão de plano de contas',
        dialog: {
            create: 'Criar versão de plano de contas',
            delete: 'Deletar versão de plano de contas',
            massDelete: 'Você tem certeza que deseja excluir esta versão de plano de contas?',
            update: 'Atualizar versão de plano de contas',
        }
    },
    'chart-account': {
        title: 'Plano de contas',
        dialog: {
            create: 'Criar plano de contas',
            delete: 'Deletar plano de contas',
            massDelete: 'Você tem certeza que deseja excluir este plano de contas?',
            update: 'Atualizar plano de contas',
        }
    },
    'status-type': {
        title: 'Tipo de status',
        dialog: {
            create: 'Criar tipo de status',
            delete: 'Deletar tipo de status',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de status?',
            update: 'Atualizar tipo de status',
        }
    },
    'status': {
        title: 'Status',
        dialog: {
            create: 'Criar status',
            delete: 'Deletar status',
            massDelete: 'Você tem certeza que deseja excluir estes status?',
            update: 'Atualizar status',
        }
    },
    'unit-measurement-type': {
        title: 'Tipo da unidade de medida',
        dialog: {
            create: 'Criar tipo da unidade de medida',
            delete: 'Deletar tipo da unidade de medida',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de unidade de medida?',
            update: 'Atualizar tipo da unidade de medida',
        }
    },
    'unit-measurement': {
        title: 'Unidade de medida',
        dialog: {
            create: 'Criar unidade de medida',
            delete: 'Deletar unidade de medida',
            massDelete: 'Você tem certeza que deseja excluir estas unidades de medida?',
            update: 'Atualizar unidade de medida',
        }
    },
    'currency': {
        title: 'Moeda',
        dialog: {
            create: 'Criar moeda',
            delete: 'Deletar moeda',
            massDelete: 'Você tem certeza que deseja excluir estas moedas?',
            update: 'Atualizar moeda',
        }
    },
    'currency-price': {
        title: 'Cotação da moeda',
        dialog: {
            create: 'Criar cotação da moeda',
            delete: 'Deletar cotação da moeda',
            massDelete: 'Você tem certeza que deseja excluir estas cotações da moeda?',
            update: 'Atualizar cotação moeda',
        }
    },
    'market-index': {
        title: 'Índice econômico',
        dialog: {
            create: 'Criar índice econômico',
            delete: 'Deletar índice econômico',
            massDelete: 'Você tem certeza que deseja excluir estes índices econômicos?',
            update: 'Atualizar índice econômico',
        }
    },
    'market-index-price': {
        title: 'Cotação do índice econômico',
        dialog: {
            create: 'Criar cotação do índice econômico',
            delete: 'Deletar cotação do índice econômico',
            massDelete: 'Você tem certeza que deseja excluir estas cotações do índice econômico?',
            update: 'Atualizar cotação do índice econômico',
        }
    },
    'frequency': {
        title: 'Frequência',
        dialog: {
            create: 'Criar frequência',
            delete: 'Deletar frequência',
            massDelete: 'Você tem certeza que deseja excluir estas frequências?',
            update: 'Atualizar frequência',
        }
    },
    'payment-condition-type': {
        title: 'Tipo da condição de pagamento',
        dialog: {
            create: 'Criar tipo da condição de pagamento',
            delete: 'Deletar tipo da condição de pagamento',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de condição de pagamento?',
            update: 'Atualizar tipo da condição de pagamento',
        }
    },
    'payment-condition': {
        title: 'Condição de pagamento',
        dialog: {
            create: 'Criar condição de pagamento',
            delete: 'Deletar condição de pagamento',
            massDelete: 'Você tem certeza que deseja excluir estas condições de pagamento?',
            update: 'Atualizar condição de pagamento',
        }
    },
    'vendor-type': {
        title: 'Tipo do fornecedor',
        dialog: {
            create: 'Criar tipo do fornecedor',
            delete: 'Deletar tipo do fornecedor',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de fornecedor?',
            update: 'Atualizar tipo do fornecedor',
        }
    },
    'vendor': {
        title: 'Fornecedor',
        dialog: {
            create: 'Criar fornecedor',
            delete: 'Deletar fornecedor',
            massDelete: 'Você tem certeza que deseja excluir estes fornecedores?',
            update: 'Atualizar fornecedor',
        }
    },
    'vendor-address': {
        title: 'Endereço do fornecedor',
        dialog: {
            create: 'Criar endereço do fornecedor',
            delete: 'Deletar endereço do fornecedor',
            massDelete: 'Você tem certeza que deseja excluir estes endereços do fornecedor?',
            update: 'Atualizar endereço do fornecedor',
        }
    },
    'customer-type': {
        title: 'Tipo do cliente',
        dialog: {
            create: 'Criar tipo do cliente',
            delete: 'Deletar tipo do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de cliente?',
            update: 'Atualizar tipo do cliente',
        }
    },
    'customer-group-type': {
        title: 'Tipo do grupo do cliente',
        dialog: {
            create: 'Criar tipo do grupo do cliente',
            delete: 'Deletar tipo do grupo do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de grupo do cliente?',
            update: 'Atualizar tipo do grupo do cliente',
        }
    },
    'customer-group': {
        title: 'Grupo do cliente',
        dialog: {
            create: 'Criar grupo do cliente',
            delete: 'Deletar grupo do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes grupos do cliente?',
            update: 'Atualizar grupo do cliente'
        }
    },
    'customer-param': {
        title: 'Parâmetro do cliente',
        dialog: {
            create: 'Criar parâmetro do cliente',
            delete: 'Deletar parâmetro do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes parâmetros do cliente?',
            update: 'Atualizar parâmetro do cliente'
        }
    },
    'customer-param-value': {
        title: 'Valor do parâmetro do cliente',
        dialog: {
            create: 'Criar valor do parâmetro do cliente',
            delete: 'Deletar valor do parâmetro do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes valores do parâmetro do cliente?',
            update: 'Atualizar valor do parâmetros do cliente'
        }
    },
    'person': {
        title: 'Pessoa física',
        dialog: {
            create: 'Criar pessoa física',
            delete: 'Deletar pessoa física',
            massDelete: 'Você tem certeza que deseja excluir estas pessoas físicas?',
            update: 'Atualizar pessoa física'
        }
    },
    'legal-person': {
        title: 'Pessoa jurídica',
        dialog: {
            create: 'Criar pessoa jurídica',
            delete: 'Deletar pessoa jurídica',
            massDelete: 'Você tem certeza que deseja excluir estas pessoas jurídicas?',
            update: 'Atualizar pessoa jurídica'
        }
    },
    'customer': {
        title: 'Cliente',
        dialog: {
            create: 'Criar cliente',
            delete: 'Deletar cliente',
            massDelete: 'Você tem certeza que deseja excluir estes clientes?',
            update: 'Atualizar cliente',
        }
    },
    'address-type': {
        title: 'Tipo do endereço',
        dialog: {
            create: 'Criar tipo do endereço',
            delete: 'Deletar tipo do endereço',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de endereço?',
            update: 'Atualizar tipo do endereço',
        }
    },
    'address': {
        title: 'Endereço',
        dialog: {
            create: 'Criar endereço',
            delete: 'Deletar endereço',
            massDelete: 'Você tem certeza que deseja excluir estes endereços?',
            update: 'Atualizar endereço',
        }
    },
    'customer-address': {
        title: 'Endereço do cliente',
        dialog: {
            create: 'Criar endereço do cliente',
            delete: 'Deletar endereço do cliente',
            massDelete: 'Você tem certeza que deseja excluir estes endereços do cliente?',
            update: 'Atualizar endereço do cliente',
        }
    },
    'product-type': {
        title: 'Tipo do produto',
        dialog: {
            create: 'Criar tipo do produto',
            delete: 'Deletar tipo do produto',
            massDelete: 'Você tem certeza que deseja excluir estes tipos do produto?',
            update: 'Atualizar tipo do produto',
        }
    },
    'product-origin': {
        title: 'Origem do produto',
        dialog: {
            create: 'Criar origem do produto',
            delete: 'Deletar origem do produto',
            massDelete: 'Você tem certeza que deseja excluir estas origens do produto?',
            update: 'Atualizar origem do produto',
        }
    },
    'product-family': {
        title: 'Família do produto',
        dialog: {
            create: 'Criar família do produto',
            delete: 'Deletar família do produto',
            massDelete: 'Você tem certeza que deseja excluir estas famílias do produto?',
            update: 'Atualizar família do produto',
        }
    },
    'product-class': {
        title: 'Classe do produto',
        dialog: {
            create: 'Criar classe do produto',
            delete: 'Deletar classe do produto',
            massDelete: 'Você tem certeza que deseja excluir estas classes do produto?',
            update: 'Atualizar classe do produto',
        }
    },
    'product-line': {
        title: 'Linha do produto',
        dialog: {
            create: 'Criar linha do produto',
            delete: 'Deletar linha do produto',
            massDelete: 'Você tem certeza que deseja excluir estas linhas do produto?',
            update: 'Atualizar linha do produto',
        }
    },
    'product-brand': {
        title: 'Marca do produto',
        dialog: {
            create: 'Criar marca do produto',
            delete: 'Deletar marca do produto',
            massDelete: 'Você tem certeza que deseja excluir estas marcas do produto?',
            update: 'Atualizar marca do produto',
        }
    },
    'product-category-type': {
        title: 'Tipo da categoria do produto',
        dialog: {
            create: 'Criar tipo da categoria do produto',
            delete: 'Deletar tipo da categoria do produto',
            massDelete: 'Você tem certeza que deseja excluir estes tipos da categoria do produto?',
            update: 'Atualizar tipo da categoria do produto',
        }
    },
    'product-category': {
        title: 'Categoria do produto',
        dialog: {
            create: 'Criar categoria do produto',
            delete: 'Deletar categoria do produto',
            massDelete: 'Você tem certeza que deseja excluir estas categorias do produto?',
            update: 'Atualizar categoria do produto',
        }
    },
    'product-param': {
        title: 'Parâmetro do produto',
        dialog: {
            create: 'Criar parâmetro do produto',
            delete: 'Deletar parâmetro do produto',
            massDelete: 'Você tem certeza que deseja excluir estes parâmetros do produto?',
            update: 'Atualizar parâmetro do produto',
        }
    },
    'product-param-value': {
        title: 'Valor do parâmetro do produto',
        dialog: {
            create: 'Criar valor do parâmetro do produto',
            delete: 'Deletar valor do parâmetro do produto',
            massDelete: 'Você tem certeza que deseja excluir estes valores do parâmetro do produto?',
            update: 'Atualizar valor do parâmetro do produto',
        }
    },
    'product': {
        title: 'Produto',
        dialog: {
            create: 'Criar produto',
            delete: 'Deletar produto',
            massDelete: 'Você tem certeza que deseja excluir estes produtos?',
            update: 'Atualizar produto',
        }
    },
    'sales-force-type': {
        title: 'Tipo da equipe de vendas',
        dialog: {
            create: 'Criar tipo da equipe de venda',
            delete: 'Deletar tipo da equipe de venda',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de equipe de vendas?',
            update: 'Atualizar tipo da equipe de venda',
        }
    },
    'sales-force': {
        title: 'Equipe de vendas',
        dialog: {
            create: 'Criar equipe de vendas',
            delete: 'Deletar equipe de vendas',
            massDelete: 'Você tem certeza que deseja excluir estas equipes de vendas?',
            update: 'Atualizar equipe de vendas',
        }
    },
    'sales-channel-type': {
        title: 'Tipo do canal de vendas',
        dialog: {
            create: 'Criar tipo do canal de vendas',
            delete: 'Deletar tipo do canal de vendas',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de canal de vendas?',
            update: 'Atualizar tipo do canal de venda',
        }
    },
    'sales-channel': {
        title: 'Canal de vendas',
        dialog: {
            create: 'Criar canal de vendas',
            delete: 'Deletar canal de vendas',
            massDelete: 'Você tem certeza que deseja excluir estes canais de vendas?',
            update: 'Atualizar canal de venda',
        }
    },








    
};