import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index'
import Meta from 'vue-meta'
import routes from './pathes'

Vue.use(VueRouter)

// function isCurrentUserAuthenticated() {
// 	return store.getters['auth/isAuthenticated']
// }

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		if (to.hash) {
			return { selector: to.hash }
		}
		return { x: 0, y: 0 }
	}
})

// router.beforeEach((to, from, next) => {
// 	console.log(to)
// 	if(to.name == 'Home'){
// 		if('token' in to.params && (to.params.token != '' || to.params.token != null || to.params.token != undefined)){
// 			next()
// 		} else {
// 			window.location.replace('http://fan.portal:8080/')
// 		}
// 	} else {
// 		if (!isCurrentUserAuthenticated()) {
// 			window.location.replace('http://fan.portal:8080/')
// 		} else {
// 			return next()
// 		}
// 	}
// 	return next()
// })

Vue.use(Meta)

export default router
