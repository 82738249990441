<template>
    <v-dialog v-model="getHelpDialog" 
        max-width="500px"
        persistent
    >
        <v-card style="position: relative;">
            <v-card-title class="light--text pr-4"
                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
            >
                <span class="headline">{{`Ajuda ${title}`}}</span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                            <v-btn v-on="on" text
                                dark
                                min-width="48px"
                                @click="cancel()"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text class="py-4">
                <p class="ma-0 text-subtitle-1">Este artigo foi útil?</p>

                <v-row>
                    <v-col class="d-flex justify-center pt-8 pb-6">
                        <v-tooltip top v-for="item in items" :key="item.id">
                            <template v-slot:activator="{on}">
                                    <v-btn icon class="mr-10" 
                                    v-on="on"
                                    @click="setSelected(item)"
                                    
                                >
                                    <v-icon class="help-icons"
                                        :class="{ 'selected-help-icon' : selected === item.id }"
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.tooltip }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-text>
            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                <v-divider class="mx-4"></v-divider>
                <v-card-actions class="px-6 py-6">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"
                        class="mr-4"
                        @click="cancel()"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn color="primary"
                        @click="sendSelected()"
                    >
                        Enviar
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            selected: null,
            items: [
                { id: 1, icon: 'mdi-emoticon-sad-outline', tooltip: 'Não me ajudou' },
                { id: 2, icon: 'mdi-emoticon-neutral-outline', tooltip: 'Não totalmente' },
                { id: 3, icon: 'mdi-emoticon-happy-outline', tooltip: 'Problema resolvido' },
            ]
        }
    },

    computed: {
		...mapGetters('help', ['getHelpDialog', 'getHelpDialogTitle']),

        title() {
            return this.getHelpDialogTitle ? ` - ${this.getHelpDialogTitle}` : ''
        }
    },

    methods: {
		...mapMutations('help', ['setHelpDialog']),

        setSelected(item) {
            this.selected = item.id
        },

        cancel() {
            // TODO send null
            this.close()
        },

        close() {
            this.selected = null
            this.setHelpDialog(false)
        },

        sendSelected() {
            // TODO send selected
            this.close()
        }
    }
}
</script>

<style scoped>
.help-icons {
    font-size: 40px !important;
}

.help-icons:hover {
    font-size: 60px !important;
    color: var(--v-primary-base);
}

.selected-help-icon {
    font-size: 60px !important;
    color: var(--v-primary-base) !important;
}
</style>
