<template>
    <div>
        <v-dialog v-model="getSessionExpiredDialog" 
            max-width="900px"
            persistent
        >
            <v-dialog v-model="passwordDialog"
                max-width="500px"
                persistent
            >
                <v-card>
                    <v-card-title class="headline pr-4">
                        Renovar sessão

                        <v-spacer></v-spacer>

                            <v-btn class="pa-0"
                                @click="passwordDialog = false"
                                text icon
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-card-title>

                    <v-card-text class="py-4">
                        <v-text-field color="primary"
                            label="Senha"
                            prepend-icon="mdi-lock"
                            :type="showPassword ? 'text' : 'password'" 
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            v-model="password"
                        ></v-text-field>
                    </v-card-text>

                    <v-card-actions class="px-6 py-6">
                        <v-spacer></v-spacer>

                        <v-btn @click="passwordDialog = false"
                            color="primary"
                            class="mr-3"
                            text
                        >
                            Cancelar
                        </v-btn>

                        <v-btn
                            color="primary"
                            @click="login()"
                            :loading="loading"
                            :disabled="!validPassword"
                        >
                            Confirmar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-card style="position: relative;">
                <v-card-title class="light--text"
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                >
                    <span class="headline">Sessão expirada</span>
                </v-card-title>
                <v-card-text class="py-4">
                    <p class="ma-0">Escolha <strong>RENOVAR</strong> para continuar de onde parou ou <strong>FINALIZAR</strong> para encerrar a sessão atual e retonar para a tela de login.</p>
                </v-card-text>
                <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                    <v-divider class="mx-4"></v-divider>
                    <v-card-actions class="px-6 py-6">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            class="mr-4"
                            @click="endSession()"
                        >
                            Finalizar
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="getUser.email.includes('venturus.org.br') ? loginWithAzure() : passwordDialog = true"
                        >
                            Renovar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import configs from '@/configs'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            passwordDialog: false,
            showPassword: false,
            password: '',
            loading: false,
            login_service: 'znap'
        }
    },

    computed: {
		...mapGetters('auth', ['getUser', 'getHash', 'getSessionExpiredDialog', 'getUrlPathName']),

        validPassword() {
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
            if(strongRegex.test(this.password)) {
                return true
            }
            else {
                return false
            }
        }
    },

    mounted() {
        window.addEventListener('keydown', this.keyDownHandler)
    },

    destroyed() {
        window.removeEventListener('keydown', this.keyDownHandler)
    },

    methods: {
		...mapMutations('auth', ['setSessionExpiredDialog', 'setEnableSessionExpiredDialog', 'setUrlPathName']),
		...mapActions('auth', ['authenticate', 'logout']),

        keyDownHandler(event) {
            if ((event.code === 'Enter' || event.code === 'NumpadEnter') && this.password.length >= 6) {
                this.login()
            }
        },

        async login() {
            this.loading = true

            try {
                const res = await this.authenticate({ email: this.getUser.email, password: this.password })
                if (res === 'success') {
                    this.$toast.success('Sessão renovada com sucesso')
                    this.setSessionExpiredDialog(false)
                    this.setEnableSessionExpiredDialog(true)
                    this.loading = false
                    if (this.getUrlPathName) this.redirectToPathName(this.getUrlPathName)
                } else {
                    this.loading = false
                    this.$fnError(res)
                }
            } catch (err) {
                this.loading = false
            }
        },

        redirectToPathName(pathName) {
            let url = null
            if (window.location.href.indexOf(':80') == -1)	{
                url = `https://${configs.system}.${configs.modulePath}.znaptech.com${pathName}`
            } else {
                url = `http://${configs.system}.${configs.modulePath}.znaptech:${configs.port}${pathName}`
            }

            this.setUrlPathName(null)
            return window.location.href = url
        },

        endSession() {
            return this.logout()
        },
        
        async loginWithAzure (){
            this.loading = true
            try {
                const payload = {
                    language: "pt",
                    module: "ZNAP_SECURITY",
                    system: "VENTURUS",
                    clientId: 11,
                    login_service: "azure",
                    url: window.location.href
                    }
                const res = await this.$http.post(this.$ipUser + 'user/login/save', payload)
                if (res) {
                    this.loading = false
                    window.location.href = this.$ipUser + 'user/login/azure'
                }
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        }
    }
}
</script>

<style>

</style>
